import React, { useState, useEffect } from 'react'
import { ChevronRight } from 'react-feather'
import AccordionComponent from './accordion'
import { Accordion } from '@reach/accordion'
import '@reach/accordion/styles.css'
import useProduct from '../../../../sea-site-theme/src/hooks/useProduct'
import useWindowResize from '../../../../sea-site-theme/src/hooks/useWindowResize'
import Magnifier from 'react-magnifier'
import Spinner from '../../../../sea-site-theme/src/components/utils/spinner'
import { navigate } from 'gatsby'
import { Data, Zoom } from '../../../../sea-site-theme/src/templates/product'

interface ProductInfoProps extends Data {
	val: number
	zoom: Zoom
}

interface ImageProps {
	description: string
	fluid: {
		src: string
		srcWebp: string
	}
}
interface ImagePropsTwo {
	roundview: string
}

type Merge = ImageProps | ImagePropsTwo
import {
	ProductInfoContainer,
	ProductContainer,
	ProductImageContainer,
	ProductImageHolder,
	ProductImagesContainer,
	ProductImageList,
	InfoContainer,
	BreadCrumbsContainer,
	Crumbs,
	SeriesCrumbs,
	ProductTitle,
	Price,
	SKU,
	CtaContainer,
	// Button,
	BookQuote,
	CompareBtn,
	FssContainer,
	TabsContainer,
	Tab,
	TabDetailsContainer,
	SpecificationsContainer,
	SpecificationsList,
	SpecificationsListItem,
	SpecDetail,
	Spec,
	SupportContainer,
	Manual,
	Brochure,
	Parts,
	SpecInnerItem,
	CircleSvg,
	ProductIframe,
	Stock,
} from './styles'

const ProductInfo = ({
	images,
	brand,
	series,
	model,
	price,
	sku,
	categories,
	features,
	specifications,
	brochure,
	manual,
	distinction,
	tags,
	brandPage,
	seoTitle,
	comparisonItem,
	seriesPage,
	degreeView,
	zoom,
	active,
	inStock,
	linkTo,
}: ProductInfoProps): JSX.Element => {
	const comparison = comparisonItem === null ? true : comparisonItem ? true : false
	const { width } = useWindowResize()

	const imageArray = [
		...images,
		{
			roundview: degreeView,
		},
	]
	const [ActiveProductImage, ReplaceActiveImage] = useState(imageArray[0] != undefined ? imageArray[0] : null)
	const [imageMap] = useState(imageArray)
	const [featuresArray, setFeatureArray] = useState(features)
	const [activeDetail, setActiveDetail] = useState(0)

	const ItemDetails = [
		{
			name: 'Features',
			active: true,
		},
		{
			name: 'Specifications',
			active: false,
		},
		{
			name: 'Support',
			active: false,
		},
	]

	const [activeTab, setActiveTab] = useState(ItemDetails)

	const activeTabHandler = (index: number) => {
		const active = activeTab.map((x, i) => {
			return i === index ? { ...x, active: true } : { ...x, active: false }
		})
		setActiveTab(active)
		setActiveDetail(index)
	}

	useEffect(() => {
		const newState = featuresArray
			? featuresArray.map((feature) => {
					return { ...feature, isOpen: false }
			  })
			: []
		setFeatureArray(newState)
	}, [])

	const brochureFile = brochure && brochure[0] && brochure[0].file.url
	const manualFile = manual && manual[0] && manual[0].file.url
	const commercialCheck = tags.includes('commercial')
	const residentialCheck = tags.includes('residential')
	const tagCheck = commercialCheck ? 'commercial' : residentialCheck ? 'residential' : ''

	const { addToTable } = useProduct()
	const checkLength = (
		key: string,
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		brandPage: string,
		tagCheck: string,
		title: string
	) => {
		const items = window.sessionStorage.getItem(key)
		const parsedItems = JSON.parse(items != null ? items : '')
		if (width > 1023) {
			if (parsedItems.length >= 3) {
				e.preventDefault()
				if (
					window.confirm(`Items to compare cannot be more than 3.
Clicking OK will redirect you to the product comparison page.`)
				) {
					window.location.href = `/product-comparison#${key}`
				}
			} else {
				addToTable(brandPage, tagCheck, title)
			}
		} else {
			if (parsedItems.length >= 2) {
				e.preventDefault()
				if (
					window.confirm(`Items to compare cannot be more than 2.
Clicking OK will redirect you to the product comparison page.`)
				) {
					window.location.href = `/product-comparison#${key}`
				}
			} else {
				addToTable(brandPage, tagCheck, title)
			}
		}
	}
	const [zoomImg, setZoomImg] = useState(zoom?.images != undefined ? zoom.images[0] : null)
	return (
		<ProductInfoContainer>
			<ProductContainer>
				<ProductImageHolder>
					<ProductImagesContainer>
						<ProductImageList>
							{imageMap.map((image: Merge, i: number) => {
								return (image as ImagePropsTwo).roundview === undefined ? (
									<li key={i}>
										{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
										<img
											src={(image as ImageProps).fluid.src}
											onClick={() => {
												ReplaceActiveImage(imageMap[i])
												setZoomImg(zoom.images[i])
											}}
											onKeyDown={() => {
												ReplaceActiveImage(imageMap[i])
												setZoomImg(zoom.images[i])
											}}
											alt={(image as ImageProps).description}
										/>
									</li>
								) : (image as ImagePropsTwo).roundview != null ? (
									<li key={i} className="roundview">
										{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
										<p
											onClick={() => ReplaceActiveImage(imageMap[i])}
											onKeyDown={() => ReplaceActiveImage(imageMap[i])}
										>
											360&deg; View
										</p>
									</li>
								) : (
									''
								)
							})}
						</ProductImageList>
					</ProductImagesContainer>
				</ProductImageHolder>
				<ProductImageContainer>
					{ActiveProductImage != null ? (
						(ActiveProductImage as ImagePropsTwo).roundview != undefined ? (
							<ProductIframe
								src={`${(ActiveProductImage as ImagePropsTwo).roundview}`}
								id="iframe360"
								frameBorder="0"
								scrolling="no"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
								allowFullScreen={true}
							></ProductIframe>
						) : (
							<Magnifier
								src={
									ActiveProductImage != null
										? images.length > 0
											? `${
													(ActiveProductImage as ImageProps).fluid.src.split('?')[0]
											  }?fm=png&fl=png8&fit=pad&w=450&h=450`
											: ''
										: ''
								}
								zoomImgSrc={
									zoomImg != null
										? images.length > 0
											? `${zoomImg.fluid.src.split('?')[0]}?fm=png&fl=png8&fit=pad&w=800&h=800`
											: ''
										: ''
								}
								// mgShowOverflow={false}
								mgWidth={200}
								mgHeight={200}
								zoomFactor={width >= 768 ? 0.8 : 1.1}
							/>
						)
					) : (
						<Spinner />
					)}
				</ProductImageContainer>
			</ProductContainer>

			<InfoContainer>
				<BreadCrumbsContainer>
					<Crumbs to={`/${brandPage ? brandPage.slug : '#'}`}>{brand}</Crumbs>
					{seriesPage != null ? (
						<>
							{brand != series ? (
								series != 'Wave' ? (
									<>
										<ChevronRight width="18px" height="18px" />
										<SeriesCrumbs to={'/' + seriesPage.slug}>{series}</SeriesCrumbs>
									</>
								) : (
									''
								)
							) : (
								''
							)}
						</>
					) : (
						''
					)}
				</BreadCrumbsContainer>
				<ProductTitle>{`${seoTitle}`}</ProductTitle>
				<SKU>{sku}</SKU>
				{distinction != null ? distinction : ''}
				<hr />
				{/* {
					<>
						{series != 'Wave' ? (
							<Price>
								{price
									? price.toLocaleString('th-TH', {
											style: 'currency',
											currency: 'SGD',
									  })
									: ''}
							</Price>
						) : (
							''
						)}
					</>
				} */}
				{
					<>
						{inStock === false ? (
							<Stock>
								<span className="nostock">Out of Stock</span>{' '}
								{inStock === false ? (
									linkTo != undefined || linkTo != null ? (
										<>
											– upgrade now to{' '}
											<span
												role="link"
												tabIndex={0}
												className="linkto"
												onClick={() => {
													navigate(`/product/${linkTo.slug}`)
												}}
												onKeyDown={() => {
													navigate(`/product/${linkTo.slug}`)
												}}
											>
												{linkTo.model}
											</span>
										</>
									) : (
										''
									)
								) : (
									''
								)}
							</Stock>
						) : (
							''
						)}
					</>
				}
				{categories === null ? (
					<CtaContainer>
						<BookQuote
							style={{ marginLeft: '0' }}
							className={active === null || active === true ? (series === 'Wave' ? '' : 'alt') : ''}
							to={
								active === null || active === true
									? brand != 'Dolphin'
										? `/support/contact/?subject=${encodeURIComponent(seoTitle.toLowerCase())}#contact-form`
										: series === 'Wave'
										? `/contact-us?subject=${encodeURIComponent(seoTitle.toLowerCase())}#contact-form`
										: `/contact-us?subject=${encodeURIComponent(seoTitle.toLowerCase())}#contact-form`
									: `/contact-us?subject=${encodeURIComponent(seoTitle.toLowerCase())}#contact-form`
							}
						>
							{active === null || active === true
								? brand != 'Dolphin'
									? 'Get a Quote'
									: series === 'Wave'
									? 'Request a Trial'
									: 'Enquire Now'
								: 'Enquire Now'}
						</BookQuote>
						<CompareBtn
							href={
								comparison ? `/product-comparison#${brandPage.slug === 'dolphin' ? tagCheck : brandPage.slug}` : '#'
							}
							className="alt"
							onClick={(e) => {
								comparison
									? checkLength(
											brandPage.slug === 'dolphin' ? tagCheck : brandPage.slug,
											e,
											brandPage.slug,
											tagCheck,
											seoTitle
									  )
									: ''
							}}
						>
							Compare
						</CompareBtn>
					</CtaContainer>
				) : categories.slug != 'spare-parts' ? (
					<CtaContainer>
						<BookQuote
							style={{ marginLeft: '0' }}
							className={active === null || active === true ? (series === 'Wave' ? '' : 'alt') : ''}
							to={
								active === null || active === true
									? brand != 'Dolphin'
										? `/support/contact/?subject=${encodeURIComponent(seoTitle.toLowerCase())}#contact-form`
										: series === 'Wave'
										? `/contact-us?subject=${encodeURIComponent(seoTitle.toLowerCase())}#contact-form`
										: `/contact-us?subject=${encodeURIComponent(seoTitle.toLowerCase())}#contact-form`
									: `/contact-us?subject=${encodeURIComponent(seoTitle.toLowerCase())}#contact-form`
							}
						>
							{active === null || active === true
								? brand != 'Dolphin'
									? 'Get a Quote'
									: series === 'Wave'
									? 'Request a Trial'
									: 'Enquire Now'
								: 'Enquire Now'}
						</BookQuote>
						<CompareBtn
							href={
								comparison ? `/product-comparison#${brandPage.slug === 'dolphin' ? tagCheck : brandPage.slug}` : '#'
							}
							className="alt"
							onClick={(e) => {
								comparison
									? checkLength(
											brandPage.slug === 'dolphin' ? tagCheck : brandPage.slug,
											e,
											brandPage.slug,
											tagCheck,
											seoTitle
									  )
									: ''
							}}
						>
							Compare
						</CompareBtn>
					</CtaContainer>
				) : (
					''
				)}

				<FssContainer>
					<TabsContainer>
						{activeTab.map((item, i) => {
							return (
								<Tab
									key={i}
									onClick={() => {
										activeTabHandler(i)
									}}
									className={item.active ? 'active' : ''}
								>
									{item.name}
								</Tab>
							)
						})}
					</TabsContainer>

					<TabDetailsContainer>
						{activeDetail === 0 ? (
							featuresArray ? (
								<Accordion collapsible multiple>
									{featuresArray.map((item, i) => {
										return <AccordionComponent item={item} key={i} />
									})}
								</Accordion>
							) : brand === 'Eco Clear' ? (
								<p>Nothing under the features</p>
							) : (
								<p>Features not yet available</p>
							)
						) : activeDetail === 1 ? (
							<SpecificationsContainer>
								<SpecificationsList>
									{specifications ? (
										specifications.map((item, i: number) => {
											return (
												<SpecificationsListItem key={i}>
													<SpecDetail>
														<Spec>{item.spec}:</Spec>{' '}
														{item.detail
															? item.detail
															: item.details.map((val, i: number) => {
																	return (
																		<SpecInnerItem key={i}>
																			<CircleSvg width="6" height="6" fill="#000" />
																			{val}
																		</SpecInnerItem>
																	)
															  })}
													</SpecDetail>
												</SpecificationsListItem>
											)
										})
									) : brand === 'Eco Clear' ? (
										<p>Nothing under the specifications</p>
									) : (
										<p>Specification not yet available</p>
									)}
								</SpecificationsList>
							</SpecificationsContainer>
						) : (
							<SupportContainer>
								<Manual href={manualFile} target="_blank">
									Manual
								</Manual>
								<Brochure href={brochureFile} target="_blank">
									Brochure
								</Brochure>
								<Parts href={'/contact-us/?subject=spare-parts'}>Parts</Parts>
							</SupportContainer>
						)}
					</TabDetailsContainer>
				</FssContainer>
			</InfoContainer>
		</ProductInfoContainer>
	)
}

export default ProductInfo
